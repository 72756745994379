import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { memo, useMemo } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { MdErrorOutline } from "react-icons/md";

import { Backdrop } from "../../../../../main/components/Backdrop";
import { DatePicker } from "../../../../../main/components/DateTimePicker/DatePicker";
import { InputMask } from "../../../../../main/components/InputMask";
import { MultiSelect } from "../../../../../main/components/MultiSelect";
import { TreeSelect } from "../../../../../main/components/TreeSelect";
import { IInvoiceFilters } from "../../../../../types/main/invoice";
import { ClientFormTextPerType } from "../../../Clients/constants/ClientType.constant";
import { useInvoicesFilters } from "../../hooks/useInvoicesFilters";

interface IInvoicesFilters {
  handleInvoicesList: (filters?: IInvoiceFilters) => Promise<void>;
}

const DynamicInputMask = () => {
  const { control } = useFormContext<IInvoiceFilters>();

  const document = useWatch({
    control,
    name: "document",
  });

  const formatMask = useMemo(() => {
    const length = document?.length ?? 0;

    if (length <= 11) return `${ClientFormTextPerType.PF.documentMask}#`;

    return ClientFormTextPerType.PJ.documentMask;
  }, [document]);

  return (
    <InputMask
      fieldname="document"
      format={formatMask}
      mask=""
      width="calc(100% - 10px)"
      fontSize="sm"
    />
  );
};

export const InvoicesFilters = memo(
  ({ handleInvoicesList }: IInvoicesFilters) => {
    const formMethods = useForm<IInvoiceFilters>();

    const {
      handleSubmit,
      formState: { errors, isSubmitting },
    } = formMethods;

    const { channels, hasFetchError, isFetching, products, onSubmitFilters } =
      useInvoicesFilters({
        handleInvoicesList,
      });

    if (isFetching) return <Backdrop />;

    if (hasFetchError) {
      return (
        <div>
          <MdErrorOutline />
        </div>
      );
    }

    return (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmitFilters)}>
          <Flex
            w="100%"
            justifyContent="flex-start"
            gap="15px"
            marginBlock="2rem"
          >
            <FormControl isInvalid={!!errors.channels} maxWidth="500px">
              <TreeSelect
                data={channels}
                name="channels"
                placeholder="Canais"
                // onSelectChange={(value) =>
                //   setValue("channels", value as number[])
                // }
                // setTreeData={setChannels}
              />
            </FormControl>

            <FormControl maxWidth="300px" isInvalid={!!errors.products}>
              <FormLabel htmlFor="products">Produtos</FormLabel>
              <MultiSelect
                buttonLabel="Produtos"
                fieldname="products"
                defaultOptions={products.map((item) => ({
                  label: item.name,
                  value: item.uuid,
                }))}
              />
            </FormControl>

            <FormControl maxWidth="300px" isInvalid={!!errors.dueDate}>
              <FormLabel htmlFor="dueDate">Vencimento</FormLabel>

              <DatePicker fieldname="dueDate" />
              <FormErrorMessage>
                {errors.dueDate && errors.dueDate?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl maxWidth="300px" isInvalid={!!errors.document}>
              <FormLabel htmlFor="document">Documento</FormLabel>

              <DynamicInputMask />
              <FormErrorMessage>
                {errors.document && errors.document?.message}
              </FormErrorMessage>
            </FormControl>
            <ButtonGroup alignItems="flex-end" mb="6px">
              <Button
                backgroundColor="blue.500"
                type="submit"
                isLoading={isSubmitting}
                leftIcon={<SearchIcon />}
              >
                Consultar
              </Button>
            </ButtonGroup>
          </Flex>
        </form>
      </FormProvider>
    );
  }
);
