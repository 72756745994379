/* eslint-disable react/no-unstable-nested-components */
import { InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, GridItem, Tooltip } from "@chakra-ui/react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useEffect, useMemo, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { format_CPF_CNPJ } from "../../../../main/common/utils/format_CPF_CNPJ";
import { getSeniorSyncMessage } from "../../../../main/common/utils/getSeniorSyncMessage";
import { Backdrop } from "../../../../main/components/Backdrop";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IClient } from "../../../../types/main/client";
import { PopoverClient } from "../components/PopoverClient";
import { allClient } from "../service/ClientService";

export const ClientList: React.FC = () => {
  const [clients, setClients] = useState<{
    data: IClient[];
    pageCount: number;
  }>({
    data: [],
    pageCount: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const handleClientList = async (
    pagination?: PaginationState,
    filters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    const response = await allClient({
      filters,
      pagination,
      sorting,
    });
    if (response.type === "success" && response.body) {
      setClients({
        data: response.body.data,
        pageCount: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer({
    pageCount: clients.pageCount,
  });

  const columns = useMemo<ColumnDef<IClient, any>[]>(
    () => [
      {
        header: "Tipo",
        accessorKey: "type",
        maxSize: 80,
      },
      {
        header: "Nome",
        id: "fullName",
        accessorKey: "fullName",
        filterFn: "fuzzy",
      },
      {
        header: "Documento cliente",
        accessorKey: "document",
        cell: (info) => format_CPF_CNPJ(info.getValue()) ?? "",
        filterFn: "document",
      },
      {
        header: "E-Mail",
        id: "email",
        accessorKey: "email",
      },
      {
        header: "Endereço",
        id: "address",
        accessorKey: "address",
        filterFn: "fuzzy",
      },
      {
        header: "Complemento",
        accessorKey: "additionalInformation",
      },
      {
        header: "Bairro",
        accessorKey: "district",
      },
      {
        header: "Cidade",
        accessorKey: "city",
        filterFn: "fuzzy",
      },
      {
        header: "Estado",
        accessorKey: "state",
      },
      {
        header: "CEP",
        accessorKey: "cep",
      },
      {
        header: "Número",
        accessorKey: "addressNumber",
        filterFn: "fuzzy",
      },
      {
        header: "IE",
        accessorKey: "ie",
        filterFn: "fuzzy",
      },
      {
        header: "CNH",
        accessorKey: "cnh",
        filterFn: "fuzzy",
      },
      {
        header: "RG",
        accessorKey: "rg",
        filterFn: "fuzzy",
      },
      {
        header: "Estado Civil",
        accessorFn: (row) => row.maritalStatus?.name,
        filterFn: "fuzzy",
      },
      {
        header: "Data de Nascimento/Fundação",
        accessorKey: "birthDate",
        cell: (info) => {
          const date = new Date(info.getValue());
          return format(date, "dd/MM/yyyy", {
            locale: ptBR,
          });
        },
        filterFn: "date",
      },
      {
        header: "Ultima atualização Senior",
        accessorKey: "lastSeniorSync",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          const { cellValue, message } = getSeniorSyncMessage(
            info,
            info.row.original.updatedAt
          );

          return (
            <Flex alignItems="center" gap={4}>
              <Tooltip label={message} fontSize="sm" marginLeft="8px">
                <InfoIcon color="blue.600" />
              </Tooltip>
              {cellValue}
            </Flex>
          );
        },
      },
      {
        header: "Ultima atualização Senior (Sacado)",
        accessorKey: "lastSeniorDraweeSync",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          const { cellValue, message } = getSeniorSyncMessage(
            info,
            info.row.original.updatedAt
          );

          return (
            <Flex alignItems="center" gap={4}>
              <Tooltip label={message} fontSize="sm" marginLeft="8px">
                <InfoIcon color="blue.600" />
              </Tooltip>
              {cellValue}
            </Flex>
          );
        },
      },
      {
        header: (info) => (
          <PopoverClient
            refetch={handleClientList}
            table={info.table}
            onLoading={setIsExporting}
          />
        ),
        id: "actions",
        cell: (info) => (
          <PopoverClient
            refetch={handleClientList}
            table={info.table}
            info={info}
            onLoading={setIsExporting}
          />
        ),
      },
    ],
    []
  );

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Clientes", href: "/clientlist" },
    { description: "Gerenciamento de clientes", href: "" },
  ];

  useEffect(() => {
    handleClientList(
      paginationParams,
      columnFiltersParams,
      columnSortingParams
    );
  }, [columnFiltersParams, columnSortingParams, paginationParams]);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de clientes
          </HLarge2xl>
          <Box>
            <CreateNewDataButton
              module={MODULES.CLIENTS}
              path="/manageclient"
              text="Cadastrar novo cliente"
            />
          </Box>
        </Flex>
        <TableServer
          refresh={handleClientList}
          data={clients.data}
          defaultColumns={columns}
          isLoading={isLoading}
          columnFiltersState={columnFiltersState}
          paginationState={paginationState}
          sortingState={sortingState}
        />
      </GridItem>
      {isExporting && <Backdrop />}
    </Wrapper>
  );
};
