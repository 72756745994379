import {
  Box,
  Button,
  Flex,
  GridItem,
  Spinner,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { customToast } from "../../../../main/common/utils/customToast";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { EmailBody } from "../../../../main/components/EmailBody";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { useFetch } from "../../../../main/hooks/useFetch";
import { IResponse } from "../../../../types/main/response";
import {
  deleteTemplate,
  dispatchEmail,
  getAllTemplates,
} from "../service/TemplateService";

const breadcrumb: IBreadcrumbProps[] = [
  { description: "Cadastros e consultas", href: "/templatelist" },
  { description: "Templates de e-mail", href: "" },
];

enum EmailType {
  "invoices" = "Faturas",
  "remittance" = "Remessas",
  "boleto" = "Boletos",
  "nfe" = "NFes",
}

const DispatchButton = ({
  type,
}: {
  type: "invoices" | "remittance" | "boleto" | "nfe";
}) => {
  const [isRequesting, setIsRequesting] = useState(false);

  const { data, isLoading, mutate } = useFetch<IResponse<number>>(
    `/email/count/${type}`
  );

  const onClick = async () => {
    if (isRequesting) return;

    if (!data?.body) {
      customToast("warning", "Não há e-mails pendentes de envio");
      return;
    }
    setIsRequesting(true);

    await dispatchEmail(type);
    mutate({
      type: "success",
      body: 0,
    });
    setIsRequesting(false);
  };

  return (
    <Button
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      variant="outline"
      colorScheme="blue"
      onClick={onClick}
      disabled={!data?.body || data?.body === 0}
      _disabled={{
        cursor: "not-allowed",
        color: "gray.500",
        opacity: "inherit",
        border: "gray.500",
      }}
    >
      Enviar e-mail ({EmailType[type]})
      {!isLoading && !isRequesting && (
        <Tag
          size="md"
          background="#4a9ef8"
          borderRadius="full"
          color="gray.50"
          title={`${EmailType[type]} pendentes de envio`}
        >
          <TagLabel>{data?.body ?? "0"}</TagLabel>
        </Tag>
      )}
      {(isLoading || isRequesting) && <Spinner size="sm" color="blue.500" />}
    </Button>
  );
};

const EmailBodyFilter = () => {
  return (
    <Flex alignItems="center">
      <Button
        aria-label="View Email Button"
        variant="outline"
        colorScheme="gray"
        cursor="default"
        width="100%"
        color="gray.500"
        _hover={{ backgroundColor: "transparent" }}
        _focus={{ backgroundColor: "transparent" }}
      >
        Clique para visualizar
      </Button>
    </Flex>
  );
};

export const TemplatesList = () => {
  const [templates, setTemplates] = useState([{}]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Nome do Template",
            accessor: "name",
          },
          {
            Header: "Título do E-Mail",
            accessor: "title",
          },
          {
            Header: "Remetente",
            accessor: "sender",
          },
          {
            Header: "Destinatários",
            accessor: "recipients",
          },
          {
            Header: "Responder para",
            accessor: "replyTo",
          },
          {
            Header: "Corpo do E-Mail",
            accessor: "component",
            id: "component",
            Filter: EmailBodyFilter,
          },
          {
            Header: "ID do Template",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const handleTemplateList = async () => {
    setIsLoading(true);
    const response = await getAllTemplates({});
    if (response.type === "success" && response.body) {
      const templateList = response.body.map(
        ({ name, recipients, title, uuid, emailBody, sender, replyTo }) => {
          const joinRecipients = recipients.join().replaceAll(",", ", ");
          return {
            name,
            recipients: joinRecipients,
            sender,
            replyTo,
            title,
            uuid,
            component: <EmailBody data={emailBody} templateName={name} />,
          };
        }
      );
      setTemplates(templateList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleTemplateList();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Templates de e-mail
          </HLarge2xl>
          <Box>
            <SimpleMenu label="Exibir colunas">
              <SelectableColumns />
            </SimpleMenu>
            <CreateNewDataButton
              module={MODULES.TEMPLATES}
              path="/managetemplate"
              text="Cadastrar novo template"
            />
          </Box>
        </Flex>
        <DataTable
          refreshTable={handleTemplateList}
          module="Templates"
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={templates}
          popup={{
            callToDelete: deleteTemplate,
            refreshTable: handleTemplateList,
            urlEdit: "/managetemplate/",
            urlView: "/viewtemplate/",
          }}
          showCheckbox={false}
        />
        <Flex marginBottom={4} gap={4}>
          <DispatchButton type="invoices" />
          <DispatchButton type="boleto" />
          <DispatchButton type="remittance" />
          <DispatchButton type="nfe" />
        </Flex>
      </GridItem>
    </Wrapper>
  );
};
