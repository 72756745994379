/* eslint-disable no-promise-executor-return */
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { CellContext, Table } from "@tanstack/react-table";
import { format } from "date-fns";
import { useCallback } from "react";
import { IconExport } from "../../../../main/common/assets";
import { transformFilterStateToQueryParams } from "../../../../main/components/DataTableServer/helpers/transformStateObject";
import { HLargexs, HSmallmd } from "../../../../main/components/Tipography";
import { CARList } from "../../../../types/main/tariffCar";
import { CarManually } from "../../shared/Components/Tariff/CarManually";
import { TariffMovementDetailsModal } from "../../TariffMovements/components/TariffMovementDetailsModal";
import { getCarCsvAllUUIDs, getCarCsvByUUIDs } from "../service/CARsService";

type PopoverCARProps = {
  info?: CellContext<CARList, any>;
  table: Table<CARList>;
  refetch: (data?: any) => Promise<void>;
  onLoading: (loading: boolean) => void;
};

export const PopoverCARList: React.FC<PopoverCARProps> = ({
  refetch,
  table,
  info,
  onLoading,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const receivable = info?.row.original;

  function downloadFile(data: string, filename: string) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${filename}_${format(new Date(), "dd-MM-yyyy_hh:mm:ss")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const exportAll = useCallback(async () => {
    onLoading(true);
    const data = await getCarCsvAllUUIDs();
    onLoading(false);
    downloadFile(data, "CAR");
  }, [onLoading]);

  const exportFiltered = useCallback(async () => {
    const dataFiltered = transformFilterStateToQueryParams(
      table.getState().columnFilters
    );

    onLoading(true);
    const data = await getCarCsvByUUIDs(dataFiltered);
    onLoading(false);
    downloadFile(data, "CAR_filtrado");
  }, [table, onLoading]);

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0" mt="15px">
                <Flex flexDir="column">
                  {!!receivable && (
                    <Box pl="24px">
                      <TariffMovementDetailsModal
                        uuid={receivable.uuid}
                        setIsLoading={onLoading}
                        onCloseMenu={onClose}
                      />{" "}
                    </Box>
                  )}
                  {!!receivable && (
                    <Box pl="24px">
                      <CarManually
                        typeButton="withIcon"
                        refreshTable={refetch}
                        uuidTariffCar={receivable.uuid}
                      />
                    </Box>
                  )}
                  {!receivable && (
                    <>
                      <HLargexs pl="24px" pt="12px" mb="6px">
                        EXPORTAR CSV
                      </HLargexs>
                      <Flex
                        pl="25.33px"
                        py="8px"
                        as="button"
                        alignItems="center"
                        _hover={{ bg: "gray.50" }}
                        onClick={exportAll}
                        width="100%"
                      >
                        <IconExport />
                        <HSmallmd ml="8.46px" color="gray.500">
                          Tabela inteira
                        </HSmallmd>
                      </Flex>
                      <Flex
                        pl="25.33px"
                        py="8px"
                        as="button"
                        alignItems="center"
                        _hover={{ bg: "gray.50" }}
                        onClick={exportFiltered}
                        width="100%"
                      >
                        <IconExport />
                        <HSmallmd ml="8.46px" color="gray.500">
                          Tabela filtrada
                        </HSmallmd>
                      </Flex>
                    </>
                  )}
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
