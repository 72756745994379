import { isBefore } from "date-fns";
import * as yup from "yup";

import { isBusinessDay } from "../../../../main/common/utils/dateHelper";

export const tariffSchema = yup
  .object({
    contractUuid: yup.string().required("Esse campo é obrigatório"),
    billerUuid: yup.string().required("Esse campo é obrigatório"),
    startDate: yup.string().required("Esse campo é obrigatório"),
    endDate: yup
      .mixed()
      .required("Esse campo é obrigatório")
      .test(
        "required",
        "A data fim deve ser maior que a data início",
        (endDate, schema) => {
          const { startDate } = schema.parent;
          const endDateIsBefore = isBefore(
            new Date(endDate),
            new Date(startDate)
          );

          if (endDateIsBefore) return false;
          return true;
        }
      ),
    dueDate: yup
      .string()
      .test(
        "validDueDate",
        "Data não pode ser menor que a data atual.",
        (dueDate) => {
          const today = new Date();

          if (!dueDate) return true;

          const date = new Date(dueDate);

          const isDueDateBefore = isBefore(
            new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              23,
              59,
              59
            ),
            today
          );

          if (isDueDateBefore) return false;
          return true;
        }
      )
      .test("businessDueDate", "Data precisa ser um dia útil.", (dueDate) => {
        if (!dueDate) return true;
        return isBusinessDay(new Date(dueDate));
      }),
    tariffCarData: yup
      .array()
      .of(
        yup
          .object()
          .required("Esse campo é obrigatório")
          .shape({
            serviceUuid: yup.string().required("Esse campo é obrigatório"),
            value: yup.number().required("Esse campo é obrigatório"),
          })
      )
      .test("tariffCarDataRequired", (value, context) => {
        const { billerUuid, contractUuid, dueDate, endDate, id, startDate } =
          context.parent;
        const fieldsValid =
          billerUuid !== "" &&
          contractUuid !== "" &&
          dueDate !== "" &&
          endDate !== "" &&
          startDate !== "" &&
          id !== "";
        if ((!value || value.length === 0) && fieldsValid) {
          return false;
        }
        return true;
      }),
  })
  .required();
