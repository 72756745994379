/* eslint-disable prettier/prettier */
export type BackgroundProcessesLogsTypes =
  | "PAYMENT_METHOD"
  | "DUE_DATE"
  | "EMAIL"
  | "PDF"
  | "PROCESSING_REMITTANCE"
  | "NFE"
  | "PRE_SAVED_INVOICE"
  | "PRE_SAVED_REMITTANCE"
  | "SENIOR_SYNC"
  | "NFE_BLOCK"
  | "ILEGAL_CAR_UPDATE"
  | "NFE_DUPLICATED"
  | "ERROR_CREATE_TARIFF"
  | "ERROR_CANCEL_TARIFF";

export enum BackgroundProcessLogsTypeDescription {
  PAYMENT_METHOD = "Método de pagamento",
  DUE_DATE = "Data de vencimento",
  EMAIL = "Envio de Email",
  PDF = "Geração PDF",
  PROCESSING_REMITTANCE = "Processamento de remessa",
  NFE = "NFe",
  PRE_SAVED_INVOICE = "Pré-Fatura",
  PRE_SAVED_REMITTANCE = "Pré-Remessa",
  SENIOR_SYNC = "Sincronização com Senior",
  NFE_BLOCK = "Trava ao gerar NFe",
  ILEGAL_CAR_UPDATE = "Atualização de CAR ilegal",
  NFE_DUPLICATED = "NFe duplicada",
  ERROR_CREATE_TARIFF = "Erro ao criar tarifa",
  ERROR_CANCEL_TARIFF = "Erro ao cancelar tarifa",
}

export type IBackgroundProcessesLogs = {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  identifier: string;
  errorDescription: string;
  type: BackgroundProcessesLogsTypes;
  extraTips?: string[];
  error?: string | null;
};

