/* eslint-disable react/no-danger */
import {
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";

import { BackgroundProcessesLogsTypes } from "../../../../../types/main/backgroundProcessesLogs";

const getRecommendationText = (type: BackgroundProcessesLogsTypes) => {
  if (type === "DUE_DATE")
    return [
      "Verifique as configurações do Canal vinculado a Tarifa.",
      "A configuração pode estar no Canal ou herdadas.",
      "Confira se há configuração do produto junto a um serviço do CAR.",
      'Atente-se a multiplas configurações diferentes para o mesmo Produto com mais de um Serviços da Tarifa, isso pode causar "dupla candidatura".',
      "Tarifa -> Contrato -> Configurações de canal.",
    ];

  if (type === "PAYMENT_METHOD") {
    return [
      "Verifique as configurações do Canal vinculado ao CAR.",
      "A configuração pode estar no Canal ou herdadas.",
      "Confira se há configuração do produto junto a um serviço do CAR.",
      `Relação: CAR -> Tarifa -> Contrato -> Configurações de canal.`,
    ];
  }

  return [];
};

export const Recommendation = ({
  type,
  extraTips,
}: {
  type: BackgroundProcessesLogsTypes;
  extraTips?: string[];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const recommendations = getRecommendationText(type);

  return (
    <Flex justifyContent="center">
      <Button variant="outline" onClick={onOpen}>
        Dica
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <List
              spacing={4}
              sx={{
                "& a": {
                  color: "blue.600",
                  "&:hover": {
                    color: "blue.800",
                  },
                },
              }}
            >
              {recommendations.map((item) => {
                return (
                  <ListItem key={item}>
                    <ListIcon as={MdSettings} color="blue.600" />
                    {item}
                  </ListItem>
                );
              })}
              {extraTips?.map((item) => {
                return (
                  <ListItem key={item}>
                    <ListIcon as={MdSettings} color="blue.600" />
                    <span dangerouslySetInnerHTML={{ __html: item }} />
                  </ListItem>
                );
              })}
            </List>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
