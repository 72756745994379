import { Alert, AlertIcon, AlertTitle, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { countBackgroundLogs } from "../../../modules/Records/BackgroundProcessesLogs/services/BackgroundProcessesLogs";
import { BackgroundProcessesLogsTypes } from "../../../types/main/backgroundProcessesLogs";

interface IAlertProps {
  text: string;
  logType: BackgroundProcessesLogsTypes;
}
export const LogAlert = ({ text, logType }: IAlertProps) => {
  const { data, isLoading } = useSWR(
    "/background-logs/count/",
    () => countBackgroundLogs(logType),
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 15, // 15 seconds
    }
  );

  const showAlert = !isLoading && !!data && data > 0;

  if (!showAlert) return null;

  return (
    <Alert status="info" mb="32px" borderRadius={4}>
      <AlertIcon />
      <AlertTitle mr={2} fontWeight={500} color="#49505c">
        {text} <b>{data}</b>.
        <Link to="/invoice-remittance-logs">
          <Button variant="link" colorScheme="facebook" ml={2}>
            Mais detalhes.
          </Button>
        </Link>
      </AlertTitle>
    </Alert>
  );
};
